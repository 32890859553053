import { Box, Combobox, Input, InputBase, rem, useCombobox } from '@mantine/core';
import React, { useState } from 'react';
import { RFC } from '@app/ui/type';


export const DocumentSelect : RFC = () => {

    const combobox = useCombobox({
        onDropdownClose : () => combobox.resetSelectedOption(),
    });

    const [value, setValue] = useState<string | null>(null);

    const groceries = [
        '📑 Legal Documentation',
        '🔬 Research Findings',
        '💡 Idea Development',
        '📊 Market Analysis',
        '📝 Patent Draft',
    ];


    const options = groceries.map(item => (
        <Combobox.Option
            value={item}
            key={item}>
            {item}
        </Combobox.Option>
    ));

    return (
        <Box
            w={rem(270)}>
            <Combobox
                size='lg'
                store={combobox}
                onOptionSubmit={val => {
                    setValue(val);
                    combobox.closeDropdown();
                }}>
                <Combobox.Target>
                    <InputBase
                        label='My Notes'
                        size='md'
                        onClick={() => combobox.toggleDropdown()}
                        rightSection={<Combobox.Chevron />}
                        rightSectionPointerEvents='none'
                        component='button'
                        pointer={true}
                        type='button'>
                        {value || <Input.Placeholder>{'Pick value'}</Input.Placeholder>}
                    </InputBase>
                </Combobox.Target>

                <Combobox.Dropdown w='100%'>
                    <Combobox.Options>{options}</Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
        </Box>
    );
};
