import { Blockquote, Box, Group, Title, rem, Text } from '@mantine/core';
import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { ChatContext } from '../ChatContext';
import { ContentQuoteProps } from '../type';
import { useHover } from '@mantine/hooks';
import { FcSms } from 'react-icons/fc';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ContentQuote : RFC<ContentQuoteProps> = ({
    researchControlId, content,
}) => {

    const { chat, belongsToModel } = useContext(ChatContext);

    const { text, citation } =  content;

    const{ hovered, ref } = useHover();

    return (
        <Box
            ref={ref}
            mb='xl'
            mt='xl'>
            <Blockquote
                color='blue'
                mt='xl'
                cite={
                    <Box pl='md'>
                        {citation}
                    </Box>
                }>
                <Group mb='md'>
                    <FcSms
                        opacity={0.8}
                        size='30px'
                        style={{
                            padding : rem(4),
                        }} />
                    <Title
                        fz={rem(30)}
                        order={2}
                        fw={200}>
                        {'Quote'}
                    </Title>
                </Group>
                <Text
                    fz={rem(18)}
                    pl='md'>
                    {text}
                </Text>
            </Blockquote>
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
