import classes from './TemplateApp.module.css';
import { Box, Text } from '@mantine/core';
import { AsideIconProps } from './type';
import { RFC } from '@app/ui/type';


export const AsideIcon : RFC<AsideIconProps> = ({
    label, icon, onClick,
}) =>
    <Box
        className={classes.asideIcon}
        onClick={onClick}>
        {icon}
        <Text className={classes.asideIconLabel}>
            {label}
        </Text>
    </Box>
