import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { CodeHighlight } from '@mantine/code-highlight';
import { ContentCodeBlockProps } from '../type';
import { ChatContext } from '../ChatContext';
import { useHover } from '@mantine/hooks';
import { Box } from '@mantine/core';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ContentCodeBlock : RFC<ContentCodeBlockProps> = ({
    researchControlId, content,
}) => {

    const { chat, belongsToModel } = useContext(ChatContext);

    const { code, language } = content;

    const { hovered, ref } = useHover();

    return (
        <Box ref={ref}>
            <CodeHighlight
                language={language}
                code={code!} />
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
