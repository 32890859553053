import { Spotlight, openSpotlight } from '@mantine/spotlight';
import { SpotLightActionList } from './constant';
import { IconSearch } from '@tabler/icons-react';
import { Card, rem, Text} from '@mantine/core';
import { RFC } from '@app/ui/type';


export const Omnibox : RFC = () => {
    return (
        <>
            <Spotlight
                style={{ border : '1px solid red' }}
                actions={SpotLightActionList}
                nothingFound='No Results'
                highlightQuery={true}
                searchProps={{
                    leftSection : <IconSearch
                        style={{ width : rem(20), height : rem(20) }}
                        stroke={1.5} />,
                    placeholder : 'Search...',
                }}/>
            <Card
                onClick={openSpotlight}
                withBorder={true}
                style={{ border : '3px #eaeaea solid' }}
                radius='xl'
                w='100%'>
                <Text
                    pl={rem(14)}
                    fz='xl'
                    c='dimmed'>
                    {'Search Anything...'}
                </Text>
            </Card>
        </>

    );
};
