import { DefaultTask } from '@app/constant/default/DefaultTask';
import { TaskContextType } from './type';
import { TaskModel } from '@app/type/model/TaskModel';
import React from 'react';


export const TaskContext = React.createContext<TaskContextType>({
    pathwayMilestoneList : [],
    showManageControls   : false,
    onChange             : (_task : TaskModel) => {},
    pathway              : null,
    task                 : DefaultTask,
});
