import { Stack, Title, ScrollArea, Box } from '@mantine/core';
import { AsideContentProps } from './type';
import classes from './style.module.css';
import { RFC } from '@app/ui/type';
import React from 'react';


export const AsideContent : RFC<AsideContentProps> = ({
    title, children,
}) => {

    return (
        <Box className={classes.aside}>
            <Stack h='100%'>
                <Title
                    ta='center'
                    fw={200}>
                    {title}
                </Title>
                <ScrollArea h='100%'>
                    {children}
                </ScrollArea>
            </Stack>
        </Box>
    );
};
