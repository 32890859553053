import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { TaskContext } from './TaskContext';
import { TaskCreate } from './TaskCreate';
import { TaskView } from './TaskView';
import { TaskRow } from './TaskRow';
import { RFC } from '@app/ui/type';
import { ReactNode } from 'react';
import { Props } from './type';


export const Task : RFC<Props> = ({
    value : task,
    mode,
    onChange,
    showManageControls = true,
    pathwayMilestoneList,
    showRowContent,
    pathway = null,
}) => {

    if (!task) return <LoadingSpinner label='Loading Task' />;

    const renderTask = () : ReactNode => {
        switch (mode) {
            case ControlModeType.Create : return <TaskCreate />;
            case ControlModeType.View   : return <TaskView />;
            case ControlModeType.Row    : return <TaskRow showRowContent={showRowContent} />;
        }
    }

    return  (
        <TaskContext.Provider
            value={{
                task, showManageControls, onChange,
                pathway, pathwayMilestoneList,
            }}>
            {renderTask()}
        </TaskContext.Provider>
    )
};
