import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import { IconName } from '@app/constant/enum/ui/IconNameEnum';
import { useDispatch, useSelector } from '@app/client/hook';
import { selectIsRecording } from '@app/client/selector';
import { Icon } from '@app/ui/static/Icon';
import { Group} from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import { Action } from '@app/client/action';


export const AudioRecorder : RFC<Props> = ({ size }) => {

    const isRecording = useSelector(selectIsRecording);

    const dispatch = useDispatch();

    const handleToggleRecording = () : void => {
        const newRecordingState = !isRecording;

        dispatch(Action.setRecorderState(newRecordingState));
    };

    const finalIconSize = size || IconSize.Medium;

    return (
        <Group onClick={handleToggleRecording}>
            {isRecording &&
                <Icon
                    iconName={IconName.MicrophoneOn}
                    size={IconSize.Large} />
            }
            {!isRecording &&
                <Icon
                    iconName={IconName.MicrophoneOff}
                    size={finalIconSize} />
            }
        </Group>

    );
};
