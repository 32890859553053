import { RFC } from '@app/ui/type';
import { Props } from './type';
import Image from 'next/image';
import {
    IconKeyframeAlignCenter, IconActivityHeartbeat, IconCurrentLocation,
    IconArrowRightTail,      IconManualGearbox,     IconMicrophoneOff,
    IconFileInvoice,         IconFileUnknown,       IconListDetails,
    IconBorderNone,          IconUserCircle,        IconMicrophone,
    IconPlayerPlay,          IconMoneybag,          IconSubtask,
    IconLogout,              IconPencil,            IconGraph,
    IconBooks,               IconCheck,             IconTool,
    IconUser,                IconSend,              IconPlus,
    IconX,
} from '@tabler/icons-react';
import {
    FcSerialTasks, FcContacts, FcCalendar,
    FcDocument,    FcTemplate, FcIdea,
} from 'react-icons/fc';
import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import { IconName } from '@app/constant/enum/ui/IconNameEnum';


export const Icon : RFC<Props> = ({
    size = IconSize.Large,
    iconName,
    imageUrl,
    onClick,
}) => {

    const style = {
        cursor : onClick ? 'pointer' : 'default',
        stroke : 1.5,
        size ,
    } as const;

    if (imageUrl)
        return <Image
            style={{ borderRadius : '50%'}}
            src={imageUrl}
            height={size}
            width={size}
            alt='Icon image' />;

    switch (iconName) {

        /* eslint-disable react/jsx-max-props-per-line */

        case IconName.ModelCacheNode : return <IconKeyframeAlignCenter onClick={onClick} {...style} />;
        case IconName.MicrophoneOff  : return <IconMicrophoneOff       onClick={onClick} {...style} />;
        case IconName.ModelActivity  : return <IconActivityHeartbeat   onClick={onClick} {...style} />;
        case IconName.ModelLocation  : return <IconCurrentLocation     onClick={onClick} {...style} />;
        case IconName.ModelAnalysis  : return <IconGraph               onClick={onClick} {...style} />;
        case IconName.MicrophoneOn   : return <IconMicrophone          onClick={onClick} {...style} />;
        case IconName.ModelMessage   : return <IconSend                onClick={onClick} {...style} />;
        case IconName.ModelPayment   : return <IconMoneybag            onClick={onClick} {...style} />;
        case IconName.ModelGeneric   : return <IconFileUnknown         onClick={onClick} {...style} />;
        case IconName.ModelContact   : return <FcContacts size={30} />;
        case IconName.ModelInvoice   : return <IconFileInvoice         onClick={onClick} {...style} />;
        case IconName.ModelAccount   :
        case IconName.Account        : return <IconUserCircle          onClick={onClick} {...style} />;
        case IconName.ModelReport    : return <FcIdea     size={30} />;
        case IconName.ModelMatter    : return <IconBooks               onClick={onClick} {...style} />;
        case IconName.ComposeLink    : return <IconPencil              onClick={onClick} {...style} />;
        case IconName.SelectPlan     : return <IconListDetails         onClick={onClick} {...style} />;
        case IconName.ModelEvent     : return <FcCalendar size={30} />;
        case IconName.ModelAsset     : return <FcDocument                size={30} />;
        case IconName.ModelUser      : return <IconUser                onClick={onClick} {...style} />;
        case IconName.ModelTask      : return <IconSubtask             onClick={onClick} {...style} />;
        case IconName.ResetForm      : return <FcSerialTasks size={30} />;
        case IconName.Dashboard      : return <FcTemplate     size={30} />;
        case IconName.ModelJob       : return <IconManualGearbox       onClick={onClick} {...style} />;
        case IconName.SignOut        : return <IconLogout              onClick={onClick} {...style} />;
        case IconName.Toolkit        : return <IconTool                onClick={onClick} {...style} />;
        case IconName.Cancel         : return <IconX                   onClick={onClick} {...style} />;
        case IconName.Check          : return <IconCheck               onClick={onClick} {...style} />;
        case IconName.Play           : return <IconPlayerPlay          onClick={onClick} {...style} />;
        case IconName.Plus           : return <IconPlus                onClick={onClick} {...style} />;

        case IconName.RightArrow : return <IconArrowRightTail onClick={onClick} {...style} />;

            /* eslint-enable react/jsx-max-props-per-line */

        case null : return <IconBorderNone
            size={size}
            stroke={2} />;
    }
};
