import { rem } from '@mantine/core';
import { SpotlightActionData } from '@mantine/spotlight';
import { IconDashboard, IconFileText, IconHome } from '@tabler/icons-react';


export const SpotLightActionList : SpotlightActionData[] = [
    {
        id          : 'home',
        label       : 'Documents',
        description : 'Browse Your Documents',
        onClick     : () => console.log('Home'),
        leftSection : <IconHome
            style={{ width : rem(24), height : rem(24) }}
            stroke={1.5} />,
    },
    {
        id          : 'dashboard',
        label       : 'Contacts',
        description : 'Find a Contact',
        onClick     : () => console.log('Dashboard'),
        leftSection : <IconDashboard
            style={{ width : rem(24), height : rem(24) }}
            stroke={1.5} />,
    },
    {
        id          : 'documentation',
        label       : 'Matters',
        description : 'Browse open Matters',
        onClick     : () => console.log('Documentation'),
        leftSection : <IconFileText
            style={{ width : rem(24), height : rem(24) }}
            stroke={1.5} />,
    },
];
