import { selectIsTemplateNavbarOpen, selectTemplateAsideStatus } from '@app/client/selector';
import { TemplateVisibilityType } from '@app/constant/enum/ui/TemplateVisibilityTypeEnum';
import { TemplateHeaderPrivate } from '../TemplateHeader/TemplateHeaderPrivate';
import { TemplateHeaderPublic } from '../TemplateHeader/TemplateHeaderPublic';
import { AppShell, Box, Paper, SimpleGrid, Title, rem } from '@mantine/core';
import { AsideStatusType } from '@app/constant/enum/ui/AsideStatusTypeEnum';
import { Dropzone, FileWithPath, PDF_MIME_TYPE } from '@mantine/dropzone';
import { DefaultAsset } from '@app/constant/default/DefaultAsset';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { SpecialId } from '@app/constant/framework/SpecialId';
import { CoreFile } from '@app/type/framework/core/CoreFile';
import { useDispatch, useSelector } from '@app/client/hook';
import { IconUpload, IconX } from '@tabler/icons-react';
import { ModelStash } from '@app/type/model/ModelStash';
import { TemplateFooter } from '../TemplateFooter';
import { TemplateAside } from '../TemplateAside';
import { P } from '@app/type/framework/data/P';
import { RestApi } from '@app/client/RestApi';
import { AsideCompact } from './AsideCompact';
import { useContext, useState } from 'react';
import { App } from '@app/ui/AppContext';
import { useUser } from '@clerk/nextjs';
import { FooterData } from './constant';
import { useRouter } from 'next/router';
import { RFC } from '@app/ui/type';
import { Navbar } from './Navbar';
import { UI } from '@app/constant/UI';
import { Props } from './type';
import Image from 'next/image';
import { Action } from '@app/client/action';


export const TemplateApp : RFC<Props>= ({
    templateVisibility, children,
}) => {

    const { isLoaded, isSignedIn } = useUser();
    const router = useRouter();

    const { isPhone } = useContext(App);

    const dispatch = useDispatch();

    const isTemplateNavbarOpen = useSelector(selectIsTemplateNavbarOpen);
    const templateAsideStatus  = useSelector(selectTemplateAsideStatus);

    // const isAsideOpen = templateAsideStatus !== AsideStatusType.Closed;

    // const isAsideOpen = templateAsideStatus !== AsideStatusType.Closed;

    const [fileList, setFileList] = useState<FileWithPath[]>([]);

    const [isUploading, setIsUploading] = useState<boolean>(false);

    if (!isLoaded)
        return null;

    if (!isSignedIn && templateVisibility === TemplateVisibilityType.Private) {
        router.push('/');

        return null;
    }

    const iconSize = 120;
    const previews = fileList.map((file, index) => {

        const imageUrl = URL.createObjectURL(file);

        return (
            <Paper
                key={`${imageUrl}-${index}`}
                shadow='sm'
                p='xl'>
                <Image
                    alt={`${file.name} AND ${file.path}`}
                    src={imageUrl}
                    height={100}
                    width={100} />
            </Paper>
        );
    });

    const onUploadComplete = (
        model      : CoreModel,
        modelStash : ModelStash,
    ) : void => {
        dispatch(Action.updateStashNoSync(modelStash));
        dispatch(Action.pushRoute(`/document/${model.id}`));
    };

    const handleDrop = async (acceptedFileList : FileWithPath[]) : P => {

        setFileList(acceptedFileList);

        // todo figure out a better way to make this page sensiteive,
        // foe instance on the enrolled pathway page it shoukd attach it to the pathway
        const parentModel = {
            ...DefaultAsset,
            hash : SpecialId.CreateHash,
        };

        acceptedFileList.forEach(file => {

            const reader = new FileReader();

            reader.onload = async evt => {

                const pendingCoreFile : CoreFile = {
                    data : evt.target!.result as string,
                    path : file.path ?? '[no path]',
                    date : file.lastModified,
                    name : file.name,
                    hash : parentModel.hash,
                    size : file.size,
                    type : file.type,
                };

                const modelAndStash = await RestApi.asset.upload(  parentModel.hash, pendingCoreFile);

                const { model, modelStash } = modelAndStash;

                setIsUploading(false);

                onUploadComplete(model, modelStash);
            };

            reader.readAsDataURL(file);
        });
    };

    return (
        <>
            <Dropzone.FullScreen
                accept={PDF_MIME_TYPE}
                onDrop={handleDrop}
                active={true}>
                <Dropzone.Accept>
                    <Box
                        pt='xl'
                        mt='xl'
                        ta='center'>
                        <IconUpload
                            stroke={1.5}
                            style={{
                                color  : 'var(--mantine-color-blue-6)',
                                height : rem(iconSize),
                                width  : rem(iconSize),
                            }} />
                        <Title
                            fz={100}
                            fw={200}>
                            {'Drop File to Upload'}
                        </Title>
                    </Box>
                </Dropzone.Accept>
                <Dropzone.Reject>
                    <IconX
                        style={{
                            width  : rem(iconSize),
                            height : rem(iconSize),
                            color  : 'var(--mantine-color-red-6)',
                        }}
                        stroke={1.5}/>
                    <Title>
                        {'File Must be PDF'}
                    </Title>
                </Dropzone.Reject>
                {isUploading &&
                    <Dropzone.Idle>
                        <Box pb='xl'>
                            <LoadingSpinner />
                        </Box>
                    </Dropzone.Idle>
                }
                <Dropzone.Idle>
                    <SimpleGrid
                        cols={4}
                        mt={previews.length > 0 ? 'xl' : 0}>
                        {previews}
                    </SimpleGrid>
                </Dropzone.Idle>
            </Dropzone.FullScreen>
            <AppShell
                withBorder={false}
                aside={{
                    width      : 350,
                    breakpoint : 'md',
                    collapsed  : {
                        desktop : templateAsideStatus === AsideStatusType.Closed,
                        mobile  : true,
                    },
                }}
                header={{
                    height : rem(isPhone
                        ? UI.Header.Height - 40
                        : UI.Header.Height),
                }}
                navbar={{
                    breakpoint : 'md',
                    width      : rem(UI.Navbar.Width),
                    collapsed  : {
                        desktop : isPhone || !isSignedIn,
                        mobile  : !isTemplateNavbarOpen,
                    },
                }} >
                <AppShell.Navbar>
                    <Navbar />
                </AppShell.Navbar>
                <AppShell.Aside>
                    <TemplateAside templateAsideStatus={templateAsideStatus} />
                </AppShell.Aside>
                <AppShell.Header>
                    {isSignedIn
                        ? <TemplateHeaderPrivate />
                        : <TemplateHeaderPublic />
                    }
                    {isSignedIn &&
                        <AppShell.Navbar>
                            <Navbar />
                        </AppShell.Navbar>
                    }
                </AppShell.Header>
                <AppShell.Main mt='md'>
                    {isSignedIn &&
                        <AsideCompact />
                    }
                    {children}
                </AppShell.Main>
                {(!isSignedIn && templateVisibility !== TemplateVisibilityType.Private) &&
                    <TemplateFooter data={FooterData} />
                }
            </AppShell>
        </>
    );
};
