import { ContentListStyle } from '@app/constant/enum/chat/ContentListStyle';
import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { Box, List, rem } from '@mantine/core';
import { ChatContext } from '../ChatContext';
import { ContentListProps } from '../type';
import { useHover } from '@mantine/hooks';
import Markdown from 'react-markdown';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import rehypeRaw from 'rehype-raw'


export const ContentList : RFC<ContentListProps> = ({
    researchControlId, content,
}) => {
    const { chat, belongsToModel } = useContext(ChatContext);

    const { items, style } = content;

    const{ hovered, ref } = useHover();

    let listType : 'unordered' | 'ordered' | undefined = undefined;

    if(style === ContentListStyle.Ordered)
        listType = 'ordered';
    else if(style === ContentListStyle.Unordered)
        listType = 'unordered';

    return (
        <Box ref={ref}>

            <List
                type={listType}
                fz={rem(22)}
                pb='xs'
                pl='xl'>
                {items!.map((item, index) =>
                    <List.Item key={`${item}-${index}`}>
                        <Markdown rehypePlugins={[rehypeRaw]}>
                            {item}
                        </Markdown>
                    </List.Item>,
                )}
            </List>
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
