import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { ContentParagraphProps } from '../type';
import { useContext, useState } from 'react';
import { ChatContext } from '../ChatContext';
import { useHover } from '@mantine/hooks';
import { Box, rem } from '@mantine/core';
import Markdown from 'react-markdown';
import { RFC } from '@app/ui/type';
import rehypeRaw from 'rehype-raw'


export const ContentParagraph : RFC<ContentParagraphProps> = ({
    researchControlId, content,
}) => {

    const { chat, belongsToModel } = useContext(ChatContext);

    const { hovered, ref } = useHover();

    const [ isContextMenuOpen, setIsContextMenuOpen]  = useState(false);

    const handleChangeContentMenu = (isOpen : boolean) : void =>
        setIsContextMenuOpen(isOpen);

    return (
        <Box
            style={{
                backgroundColor : isContextMenuOpen ? 'rgba(109, 206, 255, 0.10)' : 'transparent',
                borderRadius    : rem(10),
                boxShadow       : isContextMenuOpen ? '10px 10px 52px -4px rgba(0,0,0,0.10)' : 'none',
                outline         : isContextMenuOpen ? '1px solid #ccc' : 'none',
            }}
            pt={rem(0)}
            pb={rem(0)}
            pl={rem(8)}
            ref={ref}>
            <Box fz='xl'>
                <Markdown rehypePlugins={[rehypeRaw]}>
                    {content.text}
                </Markdown>
            </Box>
            <ContentContextMenu
                researchControlId={researchControlId}
                onChange={handleChangeContentMenu}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
