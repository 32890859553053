import { Group, Text} from '@mantine/core';
import classes from './style.module.css';
import { RFC } from '@app/ui/type';
import Link from 'next/link';
import {
    IconBrandCrunchbase, IconBrandLinkedin,
    IconBrandTwitter, IconBrandFacebook,
} from '@tabler/icons-react';


export const SocialButtons : RFC = () =>
    <Group className={classes.social}>
        <Link
            aria-label='Twitter'
            href='https://twitter.com/contructworks'>
            <Text c='dimmed'>
                <IconBrandTwitter
                    size='2.3rem'
                    stroke={1.5} />
            </Text>
        </Link>
        <Link
            aria-label='Facebook'
            href='https://www.facebook.com/constructworks'>
            <Text c='dimmed'>
                <IconBrandFacebook
                    size='2.3rem'
                    stroke={1.5} />
            </Text>
        </Link>
        <Link
            aria-label='CrunchBase'
            href='https://www.crunchbase.com/organization/constructworks-ai-inc'>
            <Text c='dimmed'>
                <IconBrandCrunchbase
                    size='2.3rem'
                    stroke={1.5} />
            </Text>
        </Link>
        <Link
            aria-label='LinkedIn'
            href='https://www.linkedin.com/company/constructworks-ai'>
            <Text c='dimmed'>
                <IconBrandLinkedin
                    size='2.3rem'
                    stroke={1.5} />
            </Text>
        </Link>
    </Group>
