import { FormEditCreateModel } from '@app/ui/form/FormEditCreateModel';
import { TaskContext } from './TaskContext';
import React, { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const TaskCreate : RFC = () => {
    const { task, onChange } = useContext(TaskContext);

    const { title, description } =  task;

    const handleChangeFieldText = (text : string, name ?: string) : void => {

        if (!name)
            throw new Error('Name and text are required');

        return onChange && onChange({
            ...task,
            [name] : text,
        });
    };

    return (
        <FormEditCreateModel
            onChange={handleChangeFieldText}
            description={description}
            label='Create a Task'
            title={title}
            value=''>
            {/* <RelatedFieldList /> */}
        </FormEditCreateModel>
    );
};
