import { Superscript } from '@tiptap/extension-superscript';
import { TextAlign } from '@tiptap/extension-text-align';
import { Highlight } from '@tiptap/extension-highlight';
import { Underline } from '@tiptap/extension-underline';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { StarterKit } from '@tiptap/starter-kit';
import { useEditor } from '@tiptap/react';
import classes from './style.module.css';
import { rem } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const TextEditor : RFC<Props> = ({
    showToolbar = true,
    minHeight = rem(800),
    content,
}) => {
    const editor = useEditor({
        content,
        extensions : [
            StarterKit,
            Underline,
            Link,
            Superscript,
            Highlight,
            TextAlign.configure({ types : ['heading', 'paragraph'] }),
        ],
    });

    return (
        <RichTextEditor
            style={{ minHeight }}
            editor={editor}>
            {showToolbar &&
                <RichTextEditor.Toolbar
                    sticky={true}
                    stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Undo />
                        <RichTextEditor.Redo />
                    </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>
            }
            <RichTextEditor.Content className={classes.content} />
        </RichTextEditor>
    );
}
