import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { Box, Group, Stack, Title, rem } from '@mantine/core';
import { ContentParagraphProps } from '../type';
import { ChatContext } from '../ChatContext';
import { useHover } from '@mantine/hooks';
import classes from '../style.module.css';
import { FcSurvey } from 'react-icons/fc';
import Markdown from 'react-markdown';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import rehypeRaw from 'rehype-raw'


export const ContentConclusion : RFC<ContentParagraphProps> = ({
    researchControlId, content,
}) => {

    const { chat, belongsToModel } = useContext(ChatContext);

    const { hovered, ref } = useHover();

    return (
        <Box
            className={classes.root}
            ref={ref}
            mb='md'
            mt='xl'
            p='xl'>
            <Stack>
                <Group>
                    <FcSurvey size='54px' />
                    <Title
                        fz={rem(48)}
                        order={1}
                        fw={200}>
                        {'Conclusion'}
                    </Title>
                </Group>
                <Box
                    fz={rem(24)}
                    fw={200}
                    pl='xl'>
                    <Markdown rehypePlugins={[rehypeRaw]} >
                        {content.text}
                    </Markdown>
                </Box>
            </Stack>
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
