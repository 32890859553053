import { PublicFooterRouteList } from '@app/constant/route/PublicFooterRouteList';


export const FooterData = [
    {
        title : 'Legal',
        links : PublicFooterRouteList.map(route => ({
            label : route.label,
            link  : route.url,
        })),
    },
];

export const formFields = {
    signIn : {
        username : {
            labelHidden : false,
            placeholder : 'Enter your email',
        },
    },
    signUp : {
        password : {
            labelHidden : false,
            label       : 'Password:',
            placeholder : 'Enter your Password:',
            isRequired  : false,
            order       : 2,
        },
        confirm_password : {
            labelHidden : false,
            label       : 'Confirm Password:',
            order       : 1,
        },
    },
    forceNewPassword : {
        password : {
            labelHidden : false,
            placeholder : 'Enter your Password:',
        },
    },
    resetPassword : {
        username : {
            labelHidden : false,
            placeholder : 'Enter your email:',
        },
    },
    confirmResetPassword : {
        confirmation_code : {
            labelHidden : false,
            placeholder : 'Enter your Confirmation Code:',
            label       : 'New Label',
            isRequired  : false,
        },
        confirm_password : {
            labelHidden : false,
            placeholder : 'Enter your Password Please:',
        },
    },
    setupTOTP : {
        QR : {
            totpIssuer   : 'test issuer',
            totpUsername : 'amplify_qr_test_user',
        },
        confirmation_code : {
            labelHidden : false,
            label       : 'New Label',
            placeholder : 'Enter your Confirmation Code:',
            isRequired  : false,
        },
    },
    confirmSignIn : {
        confirmation_code : {
            labelHidden : false,
            label       : 'New Label',
            placeholder : 'Enter your Confirmation Code:',
            isRequired  : false,
        },
    },
};
