import { DefaultChat } from '@app/constant/default/DefaultChat';
import { ChatContextType } from './type';

import React from 'react';


export const ChatContext = React.createContext<ChatContextType>({
    relatedChatListGrouped : {},
    belongsToModel         : null,
    chat                   : DefaultChat,
});
