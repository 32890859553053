import { Text, Center, Stack, rem } from '@mantine/core';
import { Oval } from 'react-loader-spinner';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const LoadingSpinner : RFC<Props> = ({ label }) =>
    <Center >
        <Stack>
            {label &&
                <Center>
                    <Text
                        fz={rem(30)}
                        fw={200}>
                        {label}
                    </Text>
                </Center>
            }
            <br/>
            <Center>
                <Oval
                    strokeWidthSecondary={2}
                    ariaLabel='Loading'
                    strokeWidth={1}
                    height={48}
                    width={48} />
            </Center>
        </Stack>
    </Center>;
