import { Text, Box, Stack, Divider } from '@mantine/core';
import Banner from '@app/asset/attorney-ai-logo-wide.png'
import { SocialButtons } from './SocialButtons';
import { useClerk } from '@clerk/nextjs';
import classes from './style.module.css';
import { Props } from './type';
import Image from 'next/image';
import { RFC } from '@app/ui/type';
import Link from 'next/link';
import React from 'react';


export const TemplateFooter : RFC<Props> = ({ data }) => {

    const {user, openSignIn, loaded} = useClerk();

    function onLogin() : void {
        if (!loaded) return;
        if (!user) openSignIn();
    }

    const groups = data.map(group => {
        const links = group.links.map(link => (
            <Link
                className={classes.link}
                key={link.label}
                href={link.link}>
                {link.label}
            </Link>
        ));

        return (
            <Stack
                gap='0px'
                key={group.title}>
                <Text
                    fs='lg'
                    fw='500'>
                    {group.title}
                </Text>
                {links}
            </Stack>
        );
    });

    return (
        <footer className={classes.footer}>
            <Stack>
                <Box
                    style={{justifyContent : 'space-around'}}
                    className={classes.inner}
                    p='md'>
                    <Box
                        className={classes.logo}
                        pl='lg'>
                        <Link href='/'>
                            <Image
                                src={Banner.src}
                                width={Banner.width / 3.2}
                                height={Banner.height / 3.2}
                                alt='AttorneyAI Square Logo' />
                        </Link>
                        <Text
                            size='xs'
                            c='dimmed'
                            onClick={onLogin}
                            className={classes.description}>
                            {'by ConstructWorks AI, Inc.'}
                        </Text>
                    </Box>
                    <div className={classes.groups}>{groups}</div>
                </Box>
                <Divider
                    w='80%'
                    style={{alignSelf : 'center' }} />
                <Box
                    style={{justifyContent : 'space-around'}}
                    className={classes.afterFooter}
                    m='lg'>
                    <Text
                        c='dimmed'
                        size='sm'>
                        {`© ${new Date().getFullYear()} ConstructWorks AI, Inc. All rights reserved.`}
                    </Text>
                    <SocialButtons />
                </Box>
            </Stack>
        </footer>
    );
}
