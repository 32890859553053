import { Box, CloseButton, Group, Paper, ScrollArea, Stack, Tabs, Text, rem } from '@mantine/core';
import { AsideStatusType } from '@app/constant/enum/ui/AsideStatusTypeEnum';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { ReferenceCollection } from '@app/ui/control/ReferenceCollection';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { DefaultPathway } from '@app/constant/default/DefaultPathway';
import { selectLookupTask } from '@app/client/selector/ListSelector';
import { DefaultChat } from '@app/constant/default/DefaultChat';
import { useDispatch, useSelector } from '@app/client/hook';
import { CA } from '@app/type/framework/core/CoreAction';
import { TextEditor } from '@app/ui/control/TextEditor';
import { DocumentSelect } from './DocumentSelect';
import { selectTask } from '@app/client/selector';
import { AsideContent } from './AsideContent';
import { useHover } from '@mantine/hooks';
import { Task } from '@app/ui/model/Task';
import React, { useState } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import { Action } from '@app/client/action';


export const TemplateAside : RFC<Props> = ({
    templateAsideStatus,
}) => {
    const dispatch = useDispatch();

    const lookupTask = useSelector(selectLookupTask);
    const taskConfig = useSelector(selectTask);

    const task = lookupTask[taskConfig.taskHash as string];

    const { hovered : _hovered, ref } = useHover();

    const handleNoOp = () : void => {};

    const handleClickAsideTabReferences = () : CA => handleAsideClick(AsideStatusType.Reference);
    const handleClickAsideTabTasks      = () : CA => handleAsideClick(AsideStatusType.Task     );
    const handleClickAsideTabNotes      = () : CA => handleAsideClick(AsideStatusType.Note     );
    const handleClickAsideClose         = () : CA => handleAsideClick(AsideStatusType.Closed   );

    const handleAsideClick = (asideStatus : AsideStatusType) : CA =>
        dispatch(Action.contextMenuUpdateAsideStatus({
            asideStatus,
        }));

    const [ _isContextMenuOpen, setIsContextMenuOpen]  = useState(false);

    const handleChangeContentMenu = (isOpen : boolean) : void =>
        setIsContextMenuOpen(isOpen);

    return (
        <Paper
            withBorder={true}
            radius='lg'
            style={{
                boxShadow : '-8px 0px 42px -14px rgba(0,0,64,0.15)',
            }}
            h='100%'
            pl='sm'>
            <Group justify='space-between'>
                <Text
                    fz={32}
                    p='lg'
                    fw={200}>
                    {templateAsideStatus}
                </Text>
                <CloseButton
                    m='sm'
                    onClick={handleClickAsideClose}
                    size='xl' />
            </Group>
            <Tabs
                value={templateAsideStatus}
                defaultValue={templateAsideStatus}>
                <Tabs.List>
                    <Tabs.Tab
                        onClick={handleClickAsideTabReferences}
                        value={AsideStatusType.Reference}>
                        <Text fz='xl'>
                            {'References'}
                        </Text>
                    </Tabs.Tab>
                    <Tabs.Tab
                        onClick={handleClickAsideTabNotes}
                        value={AsideStatusType.Note}>
                        <Text fz='xl'>
                            {'Notes'}
                        </Text>
                    </Tabs.Tab>
                    <Tabs.Tab
                        onClick={handleClickAsideTabTasks}
                        value={AsideStatusType.Task}>
                        <Text fz='xl'>
                            {'Tasks'}
                        </Text>
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value={AsideStatusType.Reference}>
                    <ScrollArea h='100%'>
                        <ReferenceCollection belongsToModel={DefaultCoreModel} />
                    </ScrollArea>
                </Tabs.Panel>
                <Tabs.Panel value={AsideStatusType.Note}>
                    <Stack
                        ref={ref}>
                        <Group
                            style={{  position : 'relative'}}
                            wrap='nowrap'
                            mt={rem(8)}
                            mb='xs'>
                            <DocumentSelect />
                            <ContentContextMenu
                                researchControlId='asideNotes'
                                belongsToModel={DefaultPathway}
                                onChange={handleChangeContentMenu}
                                isVisible={true}
                                model={DefaultPathway}
                                chat={DefaultChat} />
                        </Group>
                        <Box mr='xs'>
                            <TextEditor content='' />
                        </Box>
                    </Stack>
                </Tabs.Panel>
                <Tabs.Panel value={AsideStatusType.Task}>
                    <AsideContent title='Task'>
                        <Task
                            mode={ControlModeType.View}
                            showManageControls={false}
                            pathwayMilestoneList={[]}
                            showRowContent={false}
                            onChange={handleNoOp}
                            pathway={null}
                            value={task} />
                    </AsideContent>
                </Tabs.Panel>
            </Tabs>
        </Paper>
    )
}
