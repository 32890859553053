import { Burger, Center, Container, Grid, Group, Text, rem } from '@mantine/core';
import { selectIsTemplateNavbarOpen } from '@app/client/selector';
import HeaderBanner from '@app/asset/scale-1024x1024-icon.png';
import { AudioRecorder } from '@app/ui/control/AudioRecorder';
import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import { useDispatch, useSelector } from '@app/client/hook';
import { CA } from '@app/type/framework/core/CoreAction';
import { Omnibox } from '@app/ui/control/Omnibox';
import { ReactNode, useContext } from 'react';
import { Action } from '@app/client/action';
import classes from './style.module.css';
import { App } from '@app/ui/AppContext';
import { RFC } from '@app/ui/type';
import Image from 'next/image';
import Link from 'next/link';


export const TemplateHeaderPrivate : RFC = () => {

    const dispatch = useDispatch();

    const isTemplateNavbarOpen = useSelector(selectIsTemplateNavbarOpen)

    const handleMenuClick = () : CA => dispatch(Action.toggleTemplateNavbar())

    const { isPhone } = useContext(App);

    return (
        <Container
            pt={isPhone ? rem(0) : 'lg'}
            fluid={true}
            h='100%'
            mb='md'>
            <Grid>
                <Grid.Col
                    span={isPhone ? 1 : 3}
                    pt='lg'>
                    <Burger
                        pt={rem(32)}
                        pl={rem(6)}
                        hiddenFrom='md'
                        opened={isTemplateNavbarOpen}
                        onClick={handleMenuClick} />
                    <Branding />
                </Grid.Col>
                <Grid.Col
                    span={isPhone ? 10 : 6}
                    pt='lg'
                    pl='lg'>
                    <Center>
                        <Omnibox />
                    </Center>
                </Grid.Col>
                <Grid.Col
                    span={isPhone ? 1 : 3}
                    style={{
                        marginTop : rem(28),
                    }}>
                    <Center>
                        <AudioRecorder size={IconSize.Large} />
                    </Center>
                </Grid.Col>
            </Grid>
        </Container>
    )
}

// todo: move this
const Branding = () : ReactNode =>
    <Link
        href='/'
        className={classes.logoText}>
        <Group
            visibleFrom='md'
            align='center'
            w='100%'
            pt=''
            pl='md'>
            <Image
                src={HeaderBanner.src}
                alt='AttorneyAI Square Logo'
                width={60}
                height={60} />
            <Text
                td='none'
                fw={200}
                fz={rem(24)}>
                {'AttorneyAI'}
            </Text>
        </Group>
    </Link>
