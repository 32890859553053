import { ContentHeaderLevel } from '@app/constant/enum/chat/ContentHeaderLevel';
import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { Box, Title, TitleOrder, rem } from '@mantine/core';
import { ContentHeaderProps } from '../type';
import { ChatContext } from '../ChatContext';
import { useHover } from '@mantine/hooks';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ContentHeader : RFC<ContentHeaderProps> = ({
    researchControlId, content,
}) => {
    const { chat, belongsToModel } = useContext(ChatContext);

    const { level, text } = content;

    const{ hovered, ref } = useHover();

    const levelMap : Record<ContentHeaderLevel, TitleOrder> = {
        [ContentHeaderLevel.Unknown] : 1,
        [ContentHeaderLevel.H1     ] : 1,
        [ContentHeaderLevel.H2     ] : 2,
        [ContentHeaderLevel.H3     ] : 3,
        [ContentHeaderLevel.H4     ] : 4,
        [ContentHeaderLevel.H5     ] : 5,
        [ContentHeaderLevel.H6     ] : 6,
    };

    const order = levelMap[level!];

    return (
        <Box ref={ref}>
            <Title
                variant='gradient'
                size={rem(24)}
                order={order}
                c='blue'
                fw={400}>
                {text}
            </Title>
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
