import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { Box, Title, rem } from '@mantine/core';
import { ChatContext } from '../ChatContext';
import { ContentTitleProps } from '../type';
import { useHover } from '@mantine/hooks';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ContentTitle : RFC<ContentTitleProps> = ({
    researchControlId, content,
}) => {
    const { text } = content;

    const{ hovered, ref } = useHover();

    const { chat, belongsToModel } = useContext(ChatContext);

    return (
        <Box ref={ref}>
            <Title
                variant='gradient'
                size={rem(30)}
                order={1}
                c='blue'
                fw={300}>
                {text}
            </Title>
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
