import { IconHeart, IconBookmark, IconShare } from '@tabler/icons-react';
import { Box, Group, rem, useMantineTheme } from '@mantine/core';
import { Props } from './type';
import { RFC } from '@app/ui/type';


export const ShareBar : RFC<Props> = ({
    model : _model,
}) => {

    const theme = useMantineTheme();

    return (
        <Group
            wrap='nowrap'
            justify='space-between'
            style={{
                opacity : 0.5,
            }}
            pr='xl'>
            <Box
                pl='xl'
                ml='lg'>
            </Box>
            <Box>
                <IconHeart
                    style={{ marginLeft : rem(20), width : rem(24), height : rem(24) }}
                    color={theme.colors.red[6]} />
                <IconBookmark
                    style={{ marginLeft : rem(20), width : rem(24), height : rem(24) }}
                    color={theme.colors.yellow[7]}/>
                <IconShare
                    style={{ marginLeft : rem(20), width : rem(24), height : rem(24) }}
                    color={theme.colors.blue[6]} />
            </Box>
        </Group>

    );
};
