import { PublicHeaderRouteList } from '@app/constant/route/PublicHeaderRouteList';
import HeaderBanner from '@app/asset/scale-1024x1024-icon.png';
import { AudioRecorder } from '@app/ui/control/AudioRecorder';
import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import logo from '@app/asset/attorney-ai-logo-wide.png'
import { SignedIn, SignedOut } from '@clerk/nextjs';
import classes from './HeaderMegaMenu.module.css';
import { Omnibox } from '@app/ui/control/Omnibox';
import { useDisclosure } from '@mantine/hooks';
import { App } from '@app/ui/AppContext';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import Image from 'next/image';
import Link from 'next/link';
import {
    ScrollArea, Divider, Drawer, Burger, Box,
    Group,      Title,   Grid,   Text,   rem,
} from '@mantine/core';


export const TemplateHeaderPublic : RFC = () => {
    const [drawerOpened, {
        toggle : toggleDrawer,
        close  : closeDrawer,
    }] = useDisclosure(false);

    const { isPhone } = useContext(App);

    const router = useRouter();

    let topLogo =
        <Link
            href='/'
            className={classes.logoText}>
            <Box
                pl='lg'
                pt='md'>
                <Image
                    src={logo.src}
                    width={logo.width / 3.2}
                    height={logo.height  / 3.2}
                    alt='AttorneyAI Square Logo' />
            </Box>
        </Link>;

    if(isPhone)
        topLogo = (
            <Link
                href='/'
                className={classes.logoText}>
                <Group>
                    <Image
                        src={HeaderBanner.src}
                        alt='AttorneyAI Square Logo'
                        width={60}
                        height={60} />
                    <Text>
                        {'AttorneyAI'}
                    </Text>
                </Group>
            </Link>
        );

    return (
        <>
            <Group
                justify='space-between'
                p='lg'>
                {topLogo}
                <SignedOut>
                    <Group
                        visibleFrom='sm'
                        h='100%'
                        pr='xl'>
                        {PublicHeaderRouteList.map((route, index) => (
                            <Link
                                className={classes.link}
                                href={route.url}
                                key={index}>
                                <Text fw={router.asPath === route.url ? 'bold' : 'normal'}>
                                    {route.label}
                                </Text>
                            </Link>
                        ))}
                    </Group>
                </SignedOut>
                <SignedIn>
                    <Grid>
                        <Grid.Col span={8}>
                            <Omnibox />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <AudioRecorder size={IconSize.Large} />
                        </Grid.Col>
                    </Grid>
                </SignedIn>
                <Burger
                    onClick={toggleDrawer}
                    opened={drawerOpened}
                    hiddenFrom='sm' />
            </Group>
            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size='100%'
                padding='md'
                title={
                    <Title pt='xs'>
                        {'AttorneyAI'}
                    </Title>
                }
                hiddenFrom='sm'
                zIndex={1000000}>
                <ScrollArea
                    h={`calc(100vh - ${rem(80)})`}
                    mx='md'>
                    <Divider my='sm' />
                    {PublicHeaderRouteList.map((route, index) => (
                        <Link
                            className={classes.link}
                            href={route.url}
                            key={index}>
                            {route.label}
                        </Link>
                    ))}
                    <Link
                        href='/'
                        className={classes.link}>
                        {'Home'}
                    </Link>
                    <Link
                        href='/data-policy'
                        className={classes.link}>
                        {'Data & Privacy'}
                    </Link>
                    <Link
                        href='/developer'
                        className={classes.link}>
                        {'API'}
                    </Link>
                    <Divider my='sm' />
                </ScrollArea>
            </Drawer>
        </>
    );
};
