import { FieldText } from '@app/ui/form/FieldText';
import { Box, Text, rem } from '@mantine/core';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const FormEditCreateModel : RFC<Props> = ({
    description : _description, onChange,
    children, title, label,
}) => {
    const { isPhone } = useContext(App);

    return (
        <Box
            p={isPhone ? 0 : 'md' }
            pt='md'>
            <form>
                <Text size={rem(28)}>
                    {label}
                </Text>
                <FieldText
                    onChange={onChange}
                    value={title}
                    label='Title'
                    name='title' />
                {children}
            </form>
        </Box>
    )
};
