import { Box, Button, Container, Grid, Paper, Stepper, Text, Title, rem } from '@mantine/core';
import { TextEditor } from '@app/ui/control/TextEditor';
import { useElementSize } from '@mantine/hooks';
import { useContext, useState  } from 'react';
import { TaskContext } from './TaskContext';
import { TaskSidebar } from './TaskSidebar';
import classes from './style.module.css';
import { RFC } from '@app/ui/type';


export const TaskView : RFC = () => {

    const { task } = useContext(TaskContext);

    const { ref, width } = useElementSize();

    const isNarrow = width < 400;

    const [active, setActive] = useState(1);

    return (
        <Container
            ref={ref}
            fluid={true}>
            <Box className={classes.completeButton}>
                <Button
                    aria-label='Back'
                    variant='subtle'
                    mr='xl'
                    size='xl'>
                    {'Back'}
                </Button>
                <Button
                    aria-label='Next'
                    right='sdfa'
                    variant='outline'
                    size='xl'>
                    {'Next'}
                </Button>
            </Box>
            <Stepper
                className={classes.stepper}
                onStepClick={setActive}
                iconPosition='right'
                active={active}>
                <Stepper.Step
                    description='Create an account'
                    label='Step 1' />
                <Stepper.Step
                    description='Verify email'
                    label='Step 2' />
                <Stepper.Step
                    description='Get full access'
                    label='Step 3' />
            </Stepper>

            <Paper p={isNarrow ? '' : 'xl'}>
                <Text
                    size={isNarrow ? rem(22) : rem(80)}
                    variant='gradient'
                    fw={200}
                    gradient={{
                        from : 'blue',
                        to   : 'cyan',
                        deg  : 90,
                    }}>
                    {task.title}
                </Text>
            </Paper>

            <Grid h='100%'>
                <Grid.Col span={isNarrow ? 12 : 3} >
                    <Box
                        mt='xl'
                        pl='xl'
                        pt='xl'>
                        <TaskSidebar />
                    </Box>
                </Grid.Col>
                <Grid.Col span={isNarrow ? 12 : 9}>
                    <Box
                        h='80%'
                        mt='xl'
                        ml='xl'
                        pl='xl'
                        pt='xl'>
                        <Title
                            size={rem(42)}
                            order={2}
                            fw={400}
                            pt='md'>
                            {task.description}
                        </Title>
                        <TextEditor content='' />
                    </Box>
                </Grid.Col>
            </Grid>
        </Container>
    );
};
