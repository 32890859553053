import { Menu, Text, rem } from '@mantine/core';
import { MenuItemProps } from './type';
import { RFC } from '@app/ui/type';


export const ContextMenuItem : RFC<MenuItemProps> = ({
    onClick, label, icon,
}) =>
    <Menu.Item
        onClick={onClick}
        leftSection={icon}>
        <Text
            pl='md'
            pr='md'
            fz={rem(20)}
            fw={300}>
            {label}
        </Text>
    </Menu.Item>
