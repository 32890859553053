import { TaskStatusType } from '@app/constant/enum/model/TaskStatusTypeEnum';
import { selectLookupMilestone } from '@app/client/selector/ListSelector';
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { ComboboxItem, Group, Select } from '@mantine/core';
import { useDispatch, useSelector } from '@app/client/hook';
import { CoreItemRow } from '@app/ui/layout/CoreItemRow';
import { TaskModel } from '@app/type/model/TaskModel';
import { TaskContext } from './TaskContext';
import { Action } from '@app/client/action';
import { TaskRowProps } from './type';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const TaskRow : RFC<TaskRowProps> = ({
    showRowContent,
}) => {
    const {
        onChange, pathway, pathwayMilestoneList, task,
    } = useContext(TaskContext);

    const milestoneLookup = useSelector(selectLookupMilestone);

    const dispatch = useDispatch();

    const handleChangeMilestone = (milestoneHash : string | null, _option : ComboboxItem) : void => {

        if(!milestoneHash) return;

        const milestone = milestoneLookup[milestoneHash];

        fireUpdate(task, {
            [RT.BelongsTo] : milestone,
        });
    }

    const fireUpdate = (
        updatedTask        : TaskModel,
        relationshipConfig ?: RelationshipConfig,
    ) : void => {

        dispatch(Action.updateStashModelSync({
            model : updatedTask,
            relationshipConfig,
        }));

        onChange && onChange(updatedTask);
    }

    const handleChangeStatus = (status : string | null, _option : ComboboxItem) : void => {

        if(!status) return;

        if(!Object.values(TaskStatusType).includes(status as TaskStatusType))
            throw new Error(`Invalid status value on task ${status}`);

        fireUpdate({
            ...task,
            status : status as TaskStatusType,
        });
    }

    const milestoneOptionList = pathwayMilestoneList?.map(milestone => ({
        value : milestone.hash!,
        label : milestone.title,
    }) ?? []);

    return (
        <CoreItemRow model={task}>
            {showRowContent && (
                <Group wrap='nowrap'>
                    <Select
                        onChange={handleChangeStatus}
                        value={task.status}
                        label='Status'
                        w='100px'
                        size='xs'
                        data={[
                            TaskStatusType.Pending,
                            TaskStatusType.Complete,
                            TaskStatusType.InProgress,
                        ]}/>
                    {pathway &&
                        <Select
                            onChange={handleChangeMilestone}
                            data={milestoneOptionList}
                            searchable={false}
                            label='Milestone'
                            value={null}
                            w='100px'
                            size='xs' />
                    }
                </Group>
            )}
        </CoreItemRow>
    );
}
