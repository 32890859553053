export enum IconName {
    ModelCacheNode = 'ModelCacheNode',
    ModelActivity  = 'ModelActivity',
    ModelLocation  = 'ModelLocation',
    ModelAnalysis  = 'ModelAnalysis',
    MicrophoneOff  = 'MicrophoneOff',
    ModelAccount   = 'ModelAccount',
    ModelInvoice   = 'ModelInvoice',
    ModelMessage   = 'ModelMessage',
    ModelPayment   = 'ModelPayment',
    ModelGeneric   = 'ModelGeneric',
    ModelContact   = 'ModelContact',
    MicrophoneOn   = 'MicrophoneOn',
    ComposeLink    = 'ComposeLink',
    ModelMatter    = 'ModelMatter',
    ModelReport    = 'ModelReport',
    RightArrow     = 'RightArrow',
    SelectPlan     = 'SelectPlan',
    ModelAsset     = 'ModelAsset',
    ModelEvent     = 'ModelEvent',
    ModelTask      = 'ModelTask',
    ModelUser      = 'ModelUser',
    Dashboard      = 'Dashboard',
    ResetForm      = 'ResetForm',
    ModelJob       = 'ModelJob',
    SignOut        = 'SignOut',
    Toolkit        = 'Toolkit',
    Account        = 'Account',
    Cancel         = 'Cancel',
    Check          = 'Check',
    Play           = 'Play',
    Plus           = 'Plus',
}

