import { FcCalendar, FcContacts, FcStackOfPhotos, FcWorkflow, FcIdea } from 'react-icons/fc';
import { Box, Grid, Group, Paper, Text, rem } from '@mantine/core';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import { ShareBar } from '@app/ui/control/ShareBar';
import { MdTaskAlt } from 'react-icons/md';
import classes from './style.module.css';
import { App } from '@app/ui/AppContext';
import { RFC } from '@app/ui/type';
import { useContext } from 'react';
import { Props } from './type';


export const CoreItemRow : RFC<Props> = ({
    manageControl, hoverEffect = true,
    rightSection, children, model,
}) => {
    const { title, description } =  model;

    const IconSizePX = 44;

    const { isDesktop } = useContext(App);

    return (
        <Paper
            pl={isDesktop ? rem(23) : rem(0)}
            className={hoverEffect ? classes.panel : ''}
            radius={rem(20)}
            p='md'>
            <Grid>
                <Grid.Col span={12}>
                    <Group
                        wrap='nowrap'
                        grow={false}
                        justify='left'
                        align='start'>
                        <Box
                            w={40}
                            pt='lg'>
                            {model.modelType === ModelType.Pathway &&
                                <FcWorkflow size={IconSizePX} />
                            }
                            {model.modelType === ModelType.Chat &&
                                <FcIdea size={IconSizePX} />
                            }
                            {model.modelType === ModelType.Task &&
                                <MdTaskAlt size={IconSizePX} />
                            }
                            {model.modelType === ModelType.Location &&
                                <FcStackOfPhotos size={IconSizePX} />
                            }
                            {model.modelType === ModelType.Contact &&
                                <FcContacts size={IconSizePX} />
                            }
                            {model.modelType === ModelType.Event &&
                                <FcCalendar size={IconSizePX} />
                            }
                        </Box>
                        <Box w='100%'>
                            <Text
                                fz='xs'
                                tt='uppercase'
                                fw={700}
                                c='dimmed'>
                                {model.modelType === ModelType.Asset &&
                                    'Document'
                                }
                                {model.modelType !== ModelType.Asset && model.modelType !== ModelType.Chat &&
                                    model.modelType
                                }
                            </Text>
                            <Group
                                justify='space-between'>
                                <Text
                                    c='#238be6'
                                    fz={rem(38)}
                                    fw={200}>
                                    {title}
                                </Text>
                            </Group>

                            <Group
                                wrap='nowrap'
                                gap={10}
                                mt={5}>
                                <Text
                                    lineClamp={4}
                                    fz={rem(18)}
                                    c='#222'
                                    pr='xl'>
                                    {description}
                                </Text>
                            </Group>
                            <Group justify='end'>
                                {rightSection}
                            </Group>
                        </Box>
                        {manageControl}
                    </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                    {children}
                </Grid.Col>
                <Grid.Col span={12}>
                    <ShareBar
                        model={model}
                        size={IconSize.Medium} />
                </Grid.Col>
            </Grid>
        </Paper>
    );
}
