import { SidebarRouteList } from '@app/constant/route/SidebarRouteList';
import { NavLink, ScrollArea, Stack, Text, rem } from '@mantine/core';
import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import { IconName } from '@app/constant/enum/ui/IconNameEnum';
import { useDispatch } from '@app/client/hook';
import { ReactNode, useContext } from 'react';
import { Icon } from '@app/ui/static/Icon';
import { App } from '@app/ui/AppContext';
import { useRouter } from 'next/router';
import { RFC } from '@app/ui/type';
import { CA } from '@app/type/framework/core/CoreAction';
import Link from 'next/link';
import { Action } from '@app/client/action';

// todo - componentize these like normal

export const Navbar : RFC = () =>
    <>
        <ScrollArea
            mt='36px'
            pl='lg'
            mb='auto'>
            <SidebarRouteListButtons />
        </ScrollArea>
        <Stack
            gap='0px'
            p='md'
            mt={0}>
            <AccountButton  />
            <LogoutButton />
        </Stack>
    </>


const LogoutButton : RFC = () : ReactNode => {

    const { onLogout } = useContext(App);

    return (
        <NavLink
            onClick={onLogout}
            href='#'
            pb='md'
            label={
                <Text pl='sm'>
                    {'Logout'}
                </Text>
            }
            leftSection={
                <Icon
                    size={IconSize.Medium}
                    iconName={IconName.SignOut} />
            } />
    );
}

const AccountButton : RFC = () => {

    const dispatch = useDispatch()

    const handleClickNavLink = () : CA => dispatch(Action.clickNavLink());

    return (
        <NavLink
            onClick={handleClickNavLink}
            component={Link}
            href='/account'
            pb='md'
            label={
                <Text pl='sm'>
                    {'My Account'}
                </Text>
            }
            leftSection={
                <Icon
                    size={IconSize.Medium}
                    iconName={IconName.Account} />
            }/>
    );
}

const SidebarRouteListButtons : RFC = () => {

    const router = useRouter();
    const path = router.pathname.split('/')[1].toLowerCase();

    const dispatch = useDispatch()

    const handleClickNavLink = () : CA => dispatch(Action.clickNavLink());

    return SidebarRouteList.map(item => {

        const isSelected =
            path === item.label.toLowerCase()
            || ( path === ''
                && item.label.toLowerCase() === 'dashboard');

        return (
            <NavLink
                style={{ borderRadius : rem(12) }}
                onClick={handleClickNavLink}
                active={isSelected}
                component={Link}
                key={item.label}
                href={item.url}
                variant='light'
                pt='sm'
                pb='sm'
                leftSection={
                    <Icon
                        size={IconSize.Medium}
                        iconName={item.icon} />
                }
                label={
                    <Text
                        size='lg'
                        style={{
                            fontWeight : isSelected ? 700 : 400,
                        }}>
                        {item.label}
                    </Text>
                }/>
        )
    });
};
