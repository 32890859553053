export enum RouteHref {

    Apply = 'https://home.construct.works//apply',

    BrowseActivities = '/activities',
    BrowseCacheNodes = '/cachenodes',
    BrowseMilestones = '/milestones',
    BrowseDocuments  = '/documents',
    BrowseLocations  = '/locations',
    BrowseContacts   = '/contacts',
    BrowseAccounts   = '/accounts',
    BrowseAnalysis   = '/analysis',
    BrowseMessages   = '/messages',
    BrowseGenerics   = '/generics',
    BrowsePatentType = '/patent-types',
    BrowsePatents    = '/my-patents',

    BrowsePayments   = '/payment',
    BrowseMatters    = '/matters',
    BrowseReports    = '/report',
    BrowseAssets     = '/assets',
    Calendar         = '/events',
    BrowseChats      = '/chats',
    BrowseTasks      = '/tasks',
    BrowseUsers      = '/users',
    Blog      = 'https://blog.construct.works',

    BrowseJobs       = '/jobs',

    Toolkit          = '/toolkit',

    RentersAdvantage = '/rentersadvantage',
    ContractGenie    = '/contractgenie',
    TicketBlaster    = '/ticketblaster',
    HOAnalyzer       = '/hoanalyzer',
    AttorneyAI       = '/attorneyai',
    BobLawBot        = '/boblawbot',

    TermsOfService = '/terms-of-service',
    PrivacyPolicy  = '/privacy-policy',
    DataPolicy     = '/data-policy',
    Developer      = '/developer',

    SEOCorporatePartntership = '/corporate-partnerships',
    SEOStudentUniversity     = '/students-and-universities',
    SEOIPPatentAttorney      = '/ip-patent-attorneys',
    SEOInventor              = '/inventors',

    SEOHighlyLegalAndTechnicalAspectsOfPatents = '/pub/highly-legal-and-technical-aspects-of-patents',
    SEOCustomizedLessonsForBeginnersAndExperts = '/pub/customized-lessons-for-beginners-and-experts',
    SEOClearPriorArtInPatentApplications       = '/pub/clear-prior-art-in-patent-applications',
    SEOSpecificationInPatentApplications       = '/pub/specification-in-patent-applications',
    SEOIndustrialApplicabilityOfPatents        = '/pub/industrial-applicability-of-patents',
    SEOKanbanBoardForProjectManagement         = '/pub/kanban-board-for-project-management',
    SEOProvisionalPatentApplication            = '/pub/provisional-patent-application',
    SEODocumentUploadAndAnalysis               = '/pub/document-upload-and-analysis',
    SEOScoringAndRiskAssessment                = '/pub/scoring-and-risk-assessment',
    SEONonObviousnessInPatents                 = '/pub/non-obviousness-in-patents',
    SEOClaimsReferenceChecking                 = '/pub/claims-reference-checking',
    SEOPatentableSubjectMatter                 = '/pub/patentable-subject-matter',
    SEOBiotechnologicalPatent                  = '/pub/biotechnological-patent',
    SEONoveltyInPatents                        = '/pub/novelty-in-patents',
    SEOUtilityInPatents                        = '/pub/utility-in-patents',
    SEOSoftwarePatent                          = '/pub/software-patent',
    SEOClaimsBuilder                           = '/pub/claims-builder',
    SEODesignPatent                            = '/pub/design-patent',
    SEOPlantPatent                             = '/pub/plant-patent',
    SEONoteTaking                              = '/pub/note-taking',

    Dashboard  = '/',

    ClerkSignUpRedirect = '/clerk-sign-up-redirect',
    HomePatentLaw       = '/ip-law',
    ClerkSignOut        = '/clerk-sign-out',
    HomeTaxLaw          = '/tax-law',
    Compose             = '/compose',
    Account             = '/account',
    SignIn              = '/sign-in',
    Contact             = '/contact',
    SignUp              = '/signup',
    Health              = '/api/health',
    Plan                = '/plan',
    Home                = '/home',
    Map                 = '/map',
}


