import { RouteItemConfig } from '@app/type/framework/template/RouteItemConfig';
import { RouteGroupType } from '@app/constant/enum/ui/RouteGroupTypeEnum';
import { RouteList } from '@app/type/framework/template/RouteList';
import { IconName } from '@app/constant/enum/ui/IconNameEnum';
import { RouteHref } from '@app/constant/route/RouteHref';


export const RouteConfig : Record<RouteList, RouteItemConfig> = {

    Contact : {
        id     : 'Contact',
        groups : [
            RouteGroupType.PublicFooter,
        ],
        url   : RouteHref.Contact,
        name  : 'Contact',
        label : 'Contact Us',
        icon  : IconName.ModelMessage,
    },

    Blog : {
        id     : 'Blog',
        groups : [
            RouteGroupType.PublicFooter,
        ],
        url   : RouteHref.Blog,
        name  : 'Blog',
        label : 'Blog',
        icon  : IconName.ModelMessage,
    },

    SEOIPPatentAttorney : {
        id     : 'SEOIPPatentAttorney',
        groups : [
            RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.SEOIPPatentAttorney,
        name  : 'SEOIPPatentAttorney',
        label : 'IP Patent Attorneys',
        icon  : IconName.Dashboard,
    },

    SEOStudentUniversity : {
        id     : 'SEOStudentUniversity',
        groups : [
            RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.SEOStudentUniversity,
        name  : 'SEOStudentUniversity',
        label : 'Higher Ed',
        icon  : IconName.Dashboard,
    },
    SEOInventor : {
        id     : 'SEOInventor',
        groups : [
            RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.SEOInventor,
        name  : 'SEOInventor',
        label : 'Inventors',
        icon  : IconName.Dashboard,
    },
    SEOCorporatePartntership : {
        id     : 'SEOCorporationPartntership',
        groups : [
            RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.SEOCorporatePartntership,
        name  : 'SEOCorporatePartntership',
        label : 'Corporate',
        icon  : IconName.Dashboard,
    },

    /* eslint-disable max-len */

    SEOhighlyLegalAndTechnicalAspectsOfPatents : { id : 'SEOhighlyLegalAndTechnicalAspectsOfPatents', groups : [], url : RouteHref.SEOHighlyLegalAndTechnicalAspectsOfPatents, name : 'SEOhighlyLegalAndTechnicalAspectsOfPatents', label : 'Highly Legal And Technical Aspects Of Patents', icon : IconName.Dashboard},
    SEOcustomizedLessonsForBeginnersAndExperts : { id : 'SEOcustomizedLessonsForBeginnersAndExperts', groups : [], url : RouteHref.SEOCustomizedLessonsForBeginnersAndExperts, name : 'SEOcustomizedLessonsForBeginnersAndExperts', label : 'Customized Lessons For Beginners And Experts',  icon : IconName.Dashboard},
    SEOclearPriorArtInPatentApplications       : { id : 'SEOclearPriorArtInPatentApplications',       groups : [], url : RouteHref.SEOClearPriorArtInPatentApplications,       name : 'SEOclearPriorArtInPatentApplications',       label : 'Clear Prior Art In Patent Applications',        icon : IconName.Dashboard},
    SEOspecificationInPatentApplications       : { id : 'SEOspecificationInPatentApplications',       groups : [], url : RouteHref.SEOSpecificationInPatentApplications,       name : 'SEOspecificationInPatentApplications',       label : 'Specification In Patent Applications',          icon : IconName.Dashboard},
    SEOindustrialApplicabilityOfPatents        : { id : 'SEOindustrialApplicabilityOfPatents',        groups : [], url : RouteHref.SEOIndustrialApplicabilityOfPatents,        name : 'SEOindustrialApplicabilityOfPatents',        label : 'Industrial Applicability Of Patents',           icon : IconName.Dashboard},
    SEOkanbanBoardForProjectManagement         : { id : 'SEOkanbanBoardForProjectManagement',         groups : [], url : RouteHref.SEOKanbanBoardForProjectManagement,         name : 'SEOkanbanBoardForProjectManagement',         label : 'Kanban Board For Project Management',           icon : IconName.Dashboard},
    SEOprovisionalPatentApplication            : { id : 'SEOprovisionalPatentApplication',            groups : [], url : RouteHref.SEOProvisionalPatentApplication,            name : 'SEOprovisionalPatentApplication',            label : 'Provisional Patent Application',                icon : IconName.Dashboard},
    SEOdocumentUploadAndAnalysis               : { id : 'SEOdocumentUploadAndAnalysis',               groups : [], url : RouteHref.SEODocumentUploadAndAnalysis,               name : 'SEOdocumentUploadAndAnalysis',               label : 'Document Upload And Analysis',                  icon : IconName.Dashboard},
    SEOscoringAndRiskAssessment                : { id : 'SEOscoringAndRiskAssessment',                groups : [], url : RouteHref.SEOScoringAndRiskAssessment,                name : 'SEOscoringAndRiskAssessment',                label : 'Scoring And Risk Assessment',                   icon : IconName.Dashboard},
    SEOnonObviousnessInPatents                 : { id : 'SEOnonObviousnessInPatents',                 groups : [], url : RouteHref.SEONonObviousnessInPatents,                 name : 'SEOnonObviousnessInPatents',                 label : 'Non Obviousness In Patents',                    icon : IconName.Dashboard},
    SEOclaimsReferenceChecking                 : { id : 'SEOclaimsReferenceChecking',                 groups : [], url : RouteHref.SEOClaimsReferenceChecking,                 name : 'SEOclaimsReferenceChecking',                 label : 'Claims Reference Checking',                     icon : IconName.Dashboard},
    SEOpatentableSubjectMatter                 : { id : 'SEOpatentableSubjectMatter',                 groups : [], url : RouteHref.SEOPatentableSubjectMatter,                 name : 'SEOpatentableSubjectMatter',                 label : 'Patentable Subject Matter',                     icon : IconName.Dashboard},
    SEObiotechnologicalPatent                  : { id : 'SEObiotechnologicalPatent',                  groups : [], url : RouteHref.SEOBiotechnologicalPatent,                  name : 'SEObiotechnologicalPatent',                  label : 'Biotechnological Patent',                       icon : IconName.Dashboard},
    SEOnoveltyInPatents                        : { id : 'SEOnoveltyInPatents',                        groups : [], url : RouteHref.SEONoveltyInPatents,                        name : 'SEOnoveltyInPatents',                        label : 'Novelty In Patents',                            icon : IconName.Dashboard},
    SEOutilityInPatents                        : { id : 'SEOutilityInPatents',                        groups : [], url : RouteHref.SEOUtilityInPatents,                        name : 'SEOutilityInPatents',                        label : 'Utility In Patents',                            icon : IconName.Dashboard},
    SEOsoftwarePatent                          : { id : 'SEOsoftwarePatent',                          groups : [], url : RouteHref.SEOSoftwarePatent,                          name : 'SEOsoftwarePatent',                          label : 'Software Patent',                               icon : IconName.Dashboard},
    SEOclaimsBuilder                           : { id : 'SEOclaimsBuilder',                           groups : [], url : RouteHref.SEOClaimsBuilder,                           name : 'SEOclaimsBuilder',                           label : 'Claims Builder',                                icon : IconName.Dashboard},
    SEOdesignPatent                            : { id : 'SEOdesignPatent',                            groups : [], url : RouteHref.SEODesignPatent,                            name : 'SEOdesignPatent',                            label : 'Design Patent',                                 icon : IconName.Dashboard},
    SEOplantPatent                             : { id : 'SEOplantPatent',                             groups : [], url : RouteHref.SEOPlantPatent,                             name : 'SEOplantPatent',                             label : 'Plant Patent',                                  icon : IconName.Dashboard},
    SEOnoteTaking                              : { id : 'SEOnoteTaking',                              groups : [], url : RouteHref.SEONoteTaking,                              name : 'SEOnoteTaking',                              label : 'Note Taking',                                   icon : IconName.Dashboard},

    /* eslint-enable max-len */

    SignIn : {
        id     : 'signin',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.SignIn,
        name  : 'SignIn',
        label : 'Sign In',
        icon  : IconName.Dashboard,
    },

    Homepage : {
        id     : 'homepage',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.Home,
        name  : 'Home',
        label : 'Home',
        icon  : IconName.Dashboard,
    },

    Developer : {
        id     : 'developer',
        groups : [
            RouteGroupType.PublicHeader,
        ],
        name  : 'Developer',
        label : 'API',
        url   : RouteHref.Developer,
        icon  : IconName.ModelCacheNode,
    },

    HomepageTaxLaw : {
        id     : 'HomepageTaxLaw',
        groups : [],
        name   : 'Tax Law',
        label  : 'Tax Law',
        url    : RouteHref.HomeTaxLaw,
        icon   : IconName.ModelCacheNode,
    },

    HomepagePatentLaw : {
        id     : 'HomepagePatentLaw',
        groups : [],
        name   : 'Patent Law',
        label  : 'IP Law',
        url    : RouteHref.HomeTaxLaw,
        icon   : IconName.ModelCacheNode,
    },
    Dashboard : {
        id     : 'dashboard',
        groups : [
            RouteGroupType.Sidebar,
            RouteGroupType.DebugHub,
        ],
        name  : 'Dashboard',
        label : 'Dashboard',
        url   : RouteHref.Dashboard,
        icon  : IconName.Dashboard,
    },

    Report : {
        id     : 'patents',
        groups : [
            RouteGroupType.DebugHub,
            RouteGroupType.Sidebar,
        ],
        url   : RouteHref.BrowsePatents,
        name  : 'My Patents',
        label : 'My Patents',
        icon  : IconName.ModelReport,
    },

    Task : {
        id     : 'task',
        groups : [
            // RouteGroupType.Sidebar,
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseTasks,
        name  : 'Tasks',
        label : 'Tasks',
        icon  : IconName.ModelTask,
    },

    Asset : {
        id     : 'asset',
        name   : 'Documents',
        label  : 'Documents',
        url    : RouteHref.BrowseDocuments,
        icon   : IconName.ModelAsset,
        groups : [
            RouteGroupType.Sidebar,
            RouteGroupType.DebugHub,
        ],
    },

    Message : {
        id     : 'message',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseMessages,
        name  : 'Messages',
        label : 'Messages',
        icon  : IconName.ModelMessage,
    },

    Location : {
        id     : 'location',
        groups : [
            // RouteGroupType.Sidebar,
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseLocations,
        name  : 'Locations',
        label : 'Locations',
        icon  : IconName.ModelLocation,
    },

    Pathway : {
        id     : 'pathway',
        groups : [
            RouteGroupType.Sidebar,
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowsePatentType,
        name  : 'BrowsePatentType',
        label : 'Patent Types',
        icon  : IconName.ResetForm,
    },

    Milestone : {
        id     : 'milestone',
        groups : [
            // RouteGroupType.Sidebar,
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseMilestones,
        name  : 'Milestones',
        label : 'Milestones',
        icon  : IconName.ComposeLink,
    },

    DataPolicy : {
        id     : 'datapolicy',
        groups : [
            RouteGroupType.DebugHub,
            RouteGroupType.PublicHeader,
            RouteGroupType.PublicFooter,
        ],
        name  : 'DataPolicy',
        label : 'Legal',
        url   : RouteHref.DataPolicy,
        icon  : IconName.ModelCacheNode,
    },

    TermsOfService : {
        id     : 'termsofservice',
        groups : [
            RouteGroupType.PublicFooter,
        ],
        name  : 'TermsOfService',
        label : 'Terms of Service',
        url   : RouteHref.TermsOfService,
        icon  : IconName.ModelCacheNode,
    },
    PrivacyPolicy : {
        id     : 'privacypolicy',
        groups : [
            RouteGroupType.PublicFooter,
        ],
        name  : 'PrivacyPolicy',
        label : 'Privacy',
        url   : RouteHref.PrivacyPolicy,
        icon  : IconName.ModelCacheNode,
    },

    ConstructWorks : {
        id     : 'constructworks',
        groups : [
            // RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.Home,
        name  : 'ConstructWorks',
        label : 'ConstructWorks',
        icon  : IconName.Account,
    },

    AttorneyAI : {
        id     : 'attorneyai',
        groups : [
            // RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.AttorneyAI,
        name  : 'AttorneyAI',
        label : 'AttorneyAI',
        icon  : IconName.Account,
    },

    TicketBlaster : {
        id     : 'ticketblaster',
        groups : [
            // RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.TicketBlaster,
        name  : 'TicketBlaster',
        label : 'TicketBlaster',
        icon  : IconName.ModelPayment,
    },

    RentersAdvantage : {
        id     : 'rentersadvantage',
        groups : [
            // RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.RentersAdvantage,
        name  : 'RentersAdvantage',
        label : 'Renter\'s Advantage',
        icon  : IconName.ModelReport,
    },

    HOAnalyzer : {
        id     : 'hoanalyzer',
        groups : [
            // RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.HOAnalyzer,
        name  : 'HOAnalyzer',
        label : 'HOAnalyzer',
        icon  : IconName.Dashboard,
    },

    ContractGenie : {
        id     : 'contractgenie',
        groups : [
            // RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.ContractGenie,
        name  : 'ContractAnalayzer',
        label : 'ContractGenie',
        icon  : IconName.ModelJob,
    },

    BobLawBot : {
        id     : 'boblawbot',
        groups : [
            // RouteGroupType.PublicHeader,
        ],
        url   : RouteHref.BobLawBot,
        name  : 'BobLawBot',
        label : 'BobLawBot',
        icon  : IconName.ModelCacheNode,
    },

    BrowserClerkSignUpUrl : {
        id     : 'browserclerksignupurl',
        groups : [
            RouteGroupType.Authentication,
        ],
        name  : 'BrowserClerkSignUpUrl',
        label : 'Sign Up',
        url   : RouteHref.SignUp,
        icon  : null,
    },

    BrowserClerkSignInUrl : {
        id     : 'browserclerksigninurl',
        groups : [
            RouteGroupType.Authentication,
        ],
        name  : 'BrowserClerkSignInUrl',
        label : 'Sign In',
        url   : RouteHref.SignIn,
        icon  : null,
    },

    Compose : {
        id     : 'compose',
        groups : [
            RouteGroupType.DebugHub,
        ],
        name  : 'Compose',
        label : 'Compose',
        url   : RouteHref.Compose,
        icon  : IconName.ComposeLink,
    },

    SignOut : {
        id     : 'signout',
        groups : [
            RouteGroupType.Authentication,
        ],
        name  : 'ClerkSignOut',
        label : 'Clerk Sign Out',
        url   : RouteHref.ClerkSignOut,
        icon  : IconName.SignOut,
    },

    ClerkSignUpRedirect : {
        id     : 'clerksignupredirect',
        groups : [
            RouteGroupType.Authentication,
        ],
        name  : 'Clerk Sign Up Redirect',
        label : 'Clerk Sign Up Redirect',
        url   : RouteHref.ClerkSignUpRedirect,
        icon  : null,
    },

    Index : {
        id     : 'index',
        groups : [
            RouteGroupType.DebugHub,
            RouteGroupType.Navigation,
        ],
        name  : 'Index',
        label : 'Index',
        url   : RouteHref.Home,
        icon  : IconName.Dashboard,
    },

    Account : {
        id     : 'account',
        groups : [
            RouteGroupType.DebugHub,
        ],
        name  : 'AccountModel',
        label : 'Account Model',
        url   : RouteHref.BrowseAccounts,
        icon  : IconName.Account,
    },

    Map : {
        id     : 'map',
        groups : [
            // RouteGroupType.Sidebar,
        ],
        name  : 'Map',
        label : 'Map',
        url   : RouteHref.Map,
        icon  : IconName.ModelLocation,
    },

    UserAccount : {
        id     : 'useraccount',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.Account,
        name  : 'Account',
        label : 'Account',
        icon  : IconName.ModelAccount,
    },

    Activity : {
        id     : 'activity',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseActivities,
        name  : 'Activities',
        label : 'Activities',
        icon  : IconName.ModelActivity,
    },

    Analysis : {
        id     : 'analysis',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseAnalysis,
        name  : 'Analysis',
        label : 'Analysis',
        icon  : IconName.ModelAnalysis,
    },

    CacheNode : {
        id     : 'cachenode',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseCacheNodes,
        name  : 'CacheNnodes',
        label : 'Cache Nodes',
        icon  : IconName.ModelCacheNode,
    },

    BrowseContact : {
        id     : 'BrowseContact',
        groups : [],
        url    : RouteHref.BrowseContacts,
        name   : 'Contacts',
        label  : 'Contacts',
        icon   : IconName.ModelContact,
    },

    Chat : {
        id     : 'chat',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseChats,
        name  : 'Meetings',
        label : 'Meetings',
        icon  : IconName.ResetForm,
    },

    Calendar : {
        groups : [],
        label  : 'Calendar',
        icon   : IconName.ModelEvent,
        url    : RouteHref.Calendar,
        name   : 'Calendar',
        id     : 'event',
    },

    Toolkit : {
        id     : 'toolkit',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.Toolkit,
        name  : 'Toolkit',
        label : 'Toolkit',
        icon  : IconName.Toolkit,
    },

    Job : {
        id     : 'job',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseJobs,
        name  : 'Jobs',
        label : 'Jobs',
        icon  : IconName.ModelJob,
    },

    Payment : {
        id     : 'payment',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowsePayments,
        name  : 'Payments',
        label : 'Payments',
        icon  : IconName.ModelPayment,
    },

    Generic : {
        id     : 'generic',
        groups : [
            RouteGroupType.DebugHub,

        ],
        url   : RouteHref.BrowseGenerics,
        name  : 'Generics',
        label : 'Generics',
        icon  : IconName.ModelGeneric,
    },

    User : {
        id     : 'user',
        groups : [
            RouteGroupType.DebugHub,
        ],
        url   : RouteHref.BrowseUsers,
        name  : 'Users',
        label : 'Users',
        icon  : IconName.ModelUser,
    },
} as const;
