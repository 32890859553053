export const UI = {

    Navbar : {
        Width : 200,
    },
    Header : {
        Height : 120,
    },
    PathwayBuilderTitle : 'Patent Template',
} as const;

// export default UI;
