import { AsideStatusType } from '@app/constant/enum/ui/AsideStatusTypeEnum';
import { CA } from '@app/type/framework/core/CoreAction';
import { VscReferences } from 'react-icons/vsc';
import { CgFileDocument } from 'react-icons/cg';
import { useDispatch } from '@app/client/hook';
import { SlNotebook } from 'react-icons/sl';
import { Action } from '@app/client/action';
import { Stack, rem } from '@mantine/core';
import { AsideIcon } from './AsideIcon';
import { RFC } from '@app/ui/type';


export const AsideCompact : RFC = () => {
    const dispatch = useDispatch();

    const handleAsideOpenNotes = () : CA => handleAsideClick(AsideStatusType.Note     );
    const handleAsideOpenTasks = () : CA => handleAsideClick(AsideStatusType.Task     );
    const handleAsideOpenRefs  = () : CA => handleAsideClick(AsideStatusType.Reference);

    const handleAsideClick = (asideStatus : AsideStatusType) : CA =>
        dispatch(Action.contextMenuUpdateAsideStatus({
            asideStatus,
        }));

    return (
        <Stack
            align='center'
            ta='center'
            style={{
                paddingTop             : rem(40),
                paddingBottom          : rem(34),
                padding                : rem(12),
                position               : 'fixed',
                borderTopLeftRadius    : rem(16),
                borderBottomLeftRadius : rem(16),
                boxShadow              : '-4px 10px 75px 0px rgba(0,0,0,0.35)',
                border                 : '1px solid #aaa',
                top                    : rem(250),
                right                  : rem(0),
                width                  : rem(80),
            }}>
            <AsideIcon
                onClick={handleAsideOpenRefs}
                icon={ <VscReferences size={30} />}
                label='Refs' />
            <AsideIcon
                onClick={handleAsideOpenNotes}
                icon={ <SlNotebook size={30} />}
                label='Notes' />
            <AsideIcon
                onClick={handleAsideOpenTasks}
                icon={ <CgFileDocument size={30} />}
                label='Tasks' />
        </Stack>
    );
}
