import { ContentUnknownProps } from '../type';
import { ChatContext } from '../ChatContext';
import { useHover } from '@mantine/hooks';
import { Box } from '@mantine/core';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';


export const ContentUnknown : RFC<ContentUnknownProps> = ({
    researchControlId, content,
}) => {

    const{ hovered, ref } = useHover();

    const { chat, belongsToModel } = useContext(ChatContext);

    return (
        <Box ref={ref}>
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
}
