import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { Box, Title, rem } from '@mantine/core';
import { ChatContext } from '../ChatContext';
import { ContentLinkProps } from '../type';
import { useHover } from '@mantine/hooks';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ContentLink : RFC<ContentLinkProps> = ({
    researchControlId, content,
}) => {
    const { chat, belongsToModel } = useContext(ChatContext);

    const { text, url } = content;

    const{ hovered, ref } = useHover();

    return (
        <Box ref={ref}>
            <Title
                variant='gradient'
                size={rem(28)}
                order={2}
                c='blue'
                fw={400}>
                {'External Link'}
            </Title>
            <a href={url}>
                {text}
            </a>
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
