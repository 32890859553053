import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { ChatContext } from '../ChatContext';
import { ContentImageProps } from '../type';
import { useHover } from '@mantine/hooks';
import { Box } from '@mantine/core';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ContentImage : RFC<ContentImageProps> = ({
    researchControlId, content,
}) => {

    const { chat, belongsToModel } = useContext(ChatContext);

    const { alt, width, height, url } = content;

    const{ hovered, ref } = useHover();

    return (
        <Box ref={ref}>
            <img
                height={height}
                width={width}
                alt={alt!}
                src={url!} />
            <ContentContextMenu
                researchControlId={researchControlId}
                belongsToModel={belongsToModel!}
                isVisible={hovered}
                model={content}
                chat={chat!} />
        </Box>
    );
};
