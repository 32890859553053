import { IconGitBranch, IconGitPullRequest, IconGitCommit, IconMessageDots } from '@tabler/icons-react';
import { Timeline, Text } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const TaskSidebar : RFC = () => {
    return (
        <Timeline
            active={1}
            bulletSize={24}
            lineWidth={2}>
            <Timeline.Item
                bullet={<IconGitBranch size={12} />}
                title='Introduction'>
                <Text
                    c='dimmed'
                    size='xl'>{'The Basics'}<Text
                        variant='link'
                        component='span'
                        inherit={true}>{'fix-notifications'}</Text>{' from master'}</Text>
                <Text
                    size='xs'
                    mt={4}>{'2 hours ago'}</Text>
            </Timeline.Item>

            <Timeline.Item
                bullet={<IconGitCommit size={12} />}
                title='Digging Deeper into Patent Law'>
                <Text
                    c='dimmed'
                    size='xl'>{'Learn the ins and outs'}<Text
                        variant='link'
                        component='span'
                        inherit={true}>{'fix-notifications branch'}</Text></Text>
                <Text
                    size='xs'
                    mt={4}>{'52 minutes ago'}</Text>
            </Timeline.Item>

            <Timeline.Item
                title='Quiz Time!'
                bullet={<IconGitPullRequest size={12} />}
                lineVariant='dashed'>
                <Text
                    c='dimmed'
                    size='xl'>{'You&apos;ve submitted a request'}<Text
                        variant='link'
                        component='span'
                        inherit={true}>{'Fix incorrect notification message (#187)'}</Text></Text>
                <Text
                    size='xs'
                    mt={4}>{'34 minutes ago'}</Text>
            </Timeline.Item>

            <Timeline.Item
                title='Review'
                bullet={<IconMessageDots size={12} />}>
                <Text
                    c='dimmed'
                    size='xl'><Text
                        variant='link'
                        component='span'
                        inherit={true}>{'Robert Gluesticker'}</Text>{' left a review on your quiz'}</Text>
                <Text
                    size='xs'
                    mt={4}>{'12 minutes ago'}</Text>
            </Timeline.Item>
        </Timeline>
    );
}
