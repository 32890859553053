import { FcSurvey, FcShare, FcIdea, FcMenu, FcMultipleInputs, FcBookmark } from 'react-icons/fc';
import { AsideStatusType } from '@app/constant/enum/ui/AsideStatusTypeEnum';
import { Menu, ActionIcon, rem, Paper } from '@mantine/core';
import { ContentModel } from '@app/type/model/ContentModel';
import { CA } from '@app/type/framework/core/CoreAction';
import { ContextMenuItem } from './ContextMenuItem';
import { ClientUtil } from '@app/client/ClientUtil';
import { useDispatch } from '@app/client/hook';
import { useClipboard } from '@mantine/hooks';
import classes from './style.module.css';
import React, { useState } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import { Action } from '@app/client/action';


export const ContentContextMenu : RFC<Props> = ({
    isVisible = true, researchControlId,
    belongsToModel, onChange,
    chat, model,
}) => {
    const dispatch = useDispatch();

    const clipboard = useClipboard();

    const [opened, setOpened] = useState(false);

    const handleChange = (isOpen : boolean) : void => {
        if(onChange)
            onChange(isOpen);

        setOpened(isOpen);
    }

    const handleSaveReference = () : CA =>
        dispatch(Action.contextMenuUpdateAsideStatus({
            asideStatus : AsideStatusType.Reference,
            model,
        }));

    const handleCopyToClipboard = () : void => {
        clipboard.copy(ClientUtil.modelToClipboardText(model));

        dispatch(Action.contextMenuCopyToClipboard(model));
    };

    const handleFurtherResearch = () : CA =>
        dispatch(Action.contextMenuFurtherResearch({
            researchQueryText : (model as ContentModel).text as string,
            referenceModel    : model,
            researchControlId,
            belongsToModel,
            chat,
        }));

    const handleShareContent = () : CA =>
        dispatch(Action.contextMenuShareContent(model));

    return (
        <Menu

            onChange={handleChange}
            opened={opened}
            shadow='xl' >
            <Menu.Target>
                <Paper
                    mt={rem(4)}
                    style={{ opacity : isVisible ? 1 : 0}}
                    className={classes.icon}
                    shadow='xl'>
                    <ActionIcon
                        variant='subtle'
                        size='lg'>
                        <FcMenu
                            size={40}
                            style={{
                                opacity : 0.4,
                            }} />
                    </ActionIcon>
                </Paper>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label
                    fz={rem(28)}
                    fw={400}>
                    {'Tools'}
                </Menu.Label>
                <Menu.Divider />
                <ContextMenuItem
                    icon={<FcMultipleInputs size={28} />}
                    onClick={handleSaveReference}
                    label='Save Reference'/>
                <ContextMenuItem
                    icon={<FcIdea  size={28} />}
                    onClick={handleFurtherResearch}
                    label='Further Research'/>
                <ContextMenuItem
                    icon={<FcBookmark  size={28} />}
                    onClick={handleFurtherResearch}
                    label='Create Lesson Task'/>
                <ContextMenuItem
                    icon={<FcSurvey size={28} />}
                    onClick={handleCopyToClipboard}
                    label={clipboard.copied ? 'Copied' : 'Copy'} />
                <ContextMenuItem
                    icon={<FcShare size={28} />}
                    onClick={handleShareContent}
                    label='Share'/>
            </Menu.Dropdown>
        </Menu>

    );
}
