import { TextInput } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const FieldText : RFC<Props> = ({
    description, placeholder,
    onChange,    value,
    label,       name,
    id,
}) => {

    const handleChange = (event : React.ChangeEvent<HTMLInputElement>) : void =>
        onChange && onChange(event.currentTarget.value, name, id);

    return (
        <TextInput
            size='lg'
            radius='lg'
            mb='md'
            placeholder={placeholder}
            description={description}
            onChange={handleChange}
            withAsterisk={true}
            value={value}
            label={label} />
    );
};
