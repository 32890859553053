import { selectLookupContent, selectReferenceList } from '@app/client/selector/ListSelector';
import { ContentSection } from '@app/ui/model/Chat/Content/ContentSection';
import { Button, Group, Paper, Text, rem } from '@mantine/core';
import { useSelector } from '@app/client/hook';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';


export const ReferenceCollection : RFC<Props> = ({
    belongsToModel : _belongsToModel,
}) => {

    const referenceList = useSelector(selectReferenceList);
    const lookupContent = useSelector(selectLookupContent);

    if(referenceList.length === 0)
        return (
            <Text
                fz={rem(32)}
                ta='center'
                fs='italic'
                c='dimmed'
                fw={200}
                mt='xl'>
                {'- No References -'}
            </Text>
        )


    if(referenceList.length === 0)
        return (
            <Text
                ta='center'
                fz={rem(32)}
                mt='xl'
                fs='italic'
                c='dimmed'
                fw={200}>
                {'- No References -'}
            </Text>
        )


    return referenceList.map((reference, index) =>
        <Paper
            key={`${reference}-${index}`}
            m='md'
            shadow='md'
            pr='lg'
            withBorder={true}>
            <ContentSection
                content={lookupContent[reference as string]}
                researchControlId='[not set]' />
            <Group
                justify='center'
                mb='lg'>
                <Button.Group>
                    <Button
                        aria-label='Edit'
                        variant='default'>
                        {'Edit'}
                    </Button>
                    <Button
                        aria-label='Insert'
                        variant='default'>
                        {'Insert'}
                    </Button>
                    <Button
                        aria-label='Delete'
                        variant='default'>
                        {'Delete'}
                    </Button>
                </Button.Group>
            </Group>
        </Paper>,
    );
}
