import { RouteGroupType } from '../enum/ui/RouteGroupTypeEnum';
import { RouteConfig } from '../framework/RouteConfig';


export const PublicHeaderRouteList = Object.entries(RouteConfig)
    .filter(
        ([_key, route]) => route.groups.includes(RouteGroupType.PublicHeader),
    ).map(([, route]) => ({
        ...route,
    }));
